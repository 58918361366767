const Links =  [
  {
    path: "/",
    text: "Home",
  },
  {
    path: "/faqs/",
    text: "Support FAQs",
  },
  {
    path: "/contact/",
    text: "Contact",
  },
  {
    path: "https://support.xfivewearables.com/hc/en-us",
    text: "Support",
  },
]

export default Links;
