import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"

const FooterArea = styled.footer`
  padding-left: 1.875rem;
  padding-right: 1.875rem;
`

const NotAMedicalDevice = styled.div`
  grid-template-columns: 1fr;
  font-size: 0.775rem;
  text-align: center;
  padding-bottom: 10px;
  padding-top: 10px;
`

const Footer = () => {
  return (
    <>
      <FooterArea>
        <hr className='mt-3'></hr>
        <NotAMedicalDevice className="container">
          &#42; Not a medical device.This device and related software are not intended for use in the diagnosis of disease or other conditions, or in the cure, mitigation, treatment or prevention of disease.Users should not interpret or take clinical action based on the device output without consultation of a qualified healthcare professional.
        </NotAMedicalDevice>
        <div className="flex justify-center py-3">
          <div>&copy; {new Date().getFullYear()} {" "} American Exchange Group</div>
        </div>
        <div className="flex justify-center pb-5">
          <div><Link className="px-5 float-right" to='/privacy/' > Privacy Policy </Link></div>
          <div><Link className="px-5" to='/terms-and-conditions/' > Terms &amp; Conditions </Link></div>
          <div><Link className="px-5 float-left" to='/warranty/' > Warranty Policy</Link></div>
        </div>
      </FooterArea>
    </>
  )
}

export default Footer
