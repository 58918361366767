import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Links from "../../constants/Links"
import QuickNav from "../../constants/QuickNav"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image";
import { Link } from "gatsby"

const NavBar = styled.nav`
  padding: 0 1.875rem;
  position: fixed;
  width: 100%;
  z-index: 5;
  background-color: var(--background);
`

const NavButton = styled.button`
  position: relative;
  color: #fff;
  padding: 12px 0 17px 0;
  order: 1;
  border: none;
  background-color: transparent;
  font-size: var(--menuItem);
  font-weight: 900;
  letter-spacing: -1px;
  z-index: 10;

  &::after {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    left: 0;
    right: 0;
    bottom: 10px;
    background-color: var(--primary);
  }

  @media (min-width: 768px) {
    display: none !important;
  }
`

const NavLogo = styled.div`
  font-size: 0.875rem;
  width: 15%;
  flex-shrink: 0;
  letter-spacing: -0.5px;
  padding: 7px 0;
  z-index: 4;

  @media (min-width: 1200px) {
    font-size: 1rem;
    padding: 12px 0;
  }

  a {
    color: #fff;
    text-decoration: none;
    transition: color 0.3s;

    @media (hover: hover) {
      &:hover {
        color: var(--primary);
      }
    }
  }
`

const BuyNow = styled.div`
position: absolute;
top: 60px;
right: 0px;
`

const NavCenter = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  max-width: 1200px;
  margin: 0 auto;
`

const NavSocials = styled.div`
  display: flex;
  align-items: center;
  padding: 0 0 0 1.875rem;
  order: 2;
  z-index: 4;

  li {
    text-align: center;
    font-size: 1.5rem;
    margin-right: 1rem;
    list-style: none;

    a {
      color: #fff;
      transition: var(--transition);

      &:hover {
        color: var(--primary);
      }
    }

    &:nth-child(2) {
      margin-right: 0;
    }
  }
`

const NavLinks = styled.div`
  background-color: var(--background);
  display: flex;
  align-content: center;
  list-style: none;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  height: 100vh;
  padding: 0 1.875rem;
  margin: 0;
  visibility: hidden;
  opacity: 0;
  width: 100%;
  max-width: 500px;
  transform: translateX(-100%);
  transition: opacity 0.2s ease-in, transform 0.2s ease-in,
    visibility var(--transition) ease-in;

  @media (min-width: 768px) {
    position: relative;
    top: auto;
    left: auto;
    transform: translate(0);
    width: auto;
    height: auto;
    visibility: visible;
    opacity: 1;
    padding: 0;
  }

  &.show-nav {
    visibility: visible;
    opacity: 1;
    transform: translateX(0);
  }

  ul {
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding: 0;
    margin: 0;

    @media (min-width: 768px) {
      flex-direction: row;
    }
  }

  li {
    font-size: var(--menuItem);
    letter-spacing: -0.5px;
    position: relative;
    padding-bottom: 10px;
    margin-bottom: 10px;

    &::after {
      content: "";
      display: block;
      position: absolute;
      height: 3px;
      left: 0;
      right: 0;
      bottom: 8px;
      background-color: var(--primary);
    }

    @media (min-width: 768px) {
      padding-top: 10px;
      padding-bottom: 18px;
      margin-right: 15px;
      margin-bottom: 0;

      &::after {
        bottom: 17px;
      }
    }

    @media (min-width: 1200px) {
      padding-top: 15px;
      padding-bottom: 20px;
      margin-right: 25px;

      &::after {
        bottom: 17px;
      }
    }
  }

  a {
    color: #fff;
    text-decoration: none;
    padding: 0;
    transition: var(--transition) color;

    &:focus {
      color: var(--primary);
    }
  }

  @media (hover: hover) {
    a:hover {
      color: var(--primary);
    }
  }
`

const Spacer = styled.div`
  height: 15px;
`

const Button = styled.button`
  /* Adapt the colors based on primary prop */
  background: rgba(204, 0, 0, 1);

  font-size: 15px;
  letter-spacing: 0.6;
  font-size: 1.07143rem;
  margin: 1em;
  line-height: 24px;
  padding: 0.75857rem 1.57143rem;
  border-radius: 999rem;
  a {
    text-decoration: none;
  }

  @media (hover: hover) {
    a:hover {
      color: black;
    }
  }
`;

const XLink = ({ className, children }) => (
  <a className={className} href="https://www.jcpenney.com/s?searchTerm=x-five">
    {children}
  </a>
);

const StyledLink = styled(XLink)`
  color: white;
  font-weight: bold;
`;

const Navbar = () => {
  const [isOpen, setNav] = useState(false)
  const toggleNav = () => {
    setNav(isOpen => !isOpen)
  }

  const imageData = graphql`query logoDark {
  fluid: file(relativePath: {eq: "xfiveLargeLogo_dark_bg.png"}) {
    childImageSharp {
      gatsbyImageData(width: 200, layout: CONSTRAINED)
    }
  }
}
`
  const data = useStaticQuery(imageData)

  return <>
    <NavBar>
      <BuyNow>
        <Button secondary>
          <StyledLink>
            Buy Now
              </StyledLink>
        </Button>
      </BuyNow>
      <NavCenter>
        <NavLogo>
          <Link bg="var(--background)" to="/">
            <GatsbyImage image={data.fluid.childImageSharp.gatsbyImageData} alt="X-Five" />
          </Link>
        </NavLogo>
        <NavButton type="button" onClick={toggleNav}>
          Menu
        </NavButton>
        <NavLinks
          className={
            isOpen ? `${"navbar-links"} ${"show-nav"}` : `${"navbar-links"}`
          }
        >
          <ul>
            {Links.map((item, index) => {
              return (
                <li key={index}>
                  {
                    item.path.startsWith('https://') ?
                      <a bg="var(--background)" href={item.path} onClick={toggleNav}>{item.text}</a> :
                      <Link bg="var(--background)" to={item.path} onClick={toggleNav}>{item.text}</Link>
                  }
                </li>
              )
            })}
          </ul>
        </NavLinks>
        <NavSocials>
          {QuickNav.map((item, index) => {
            return (
              <li key={index}>
                <Link bg="var(--background)" to={item.path}>
                  {item.icon}
                </Link>
              </li>
            )
          })}
        </NavSocials>
      </NavCenter>
    </NavBar>
    <Spacer />
  </>;
}

export default Navbar
