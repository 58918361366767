import React from "react"
import { MdHome, MdEmail } from "react-icons/md"

const quickNav = [
  {
    icon: <MdEmail />,
    path: "/contact/",
  },
  {
    icon: <MdHome />,
    path: "/",
  },
]

export default quickNav;
